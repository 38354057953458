<template>
  <div>
    <el-upload
      :class="[
        fileLists.length < options.limit ? '' : 'noneBtnImg',
        'image-upload',
      ]"
      :action="options.uploadUrl"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :limit="options.limit"
      :file-list="fileLists"
      list-type="picture-card"
      :data="uploadData"
      :on-preview="handlePictureCardPreview"
    >
      <i class="el-icon-plus" />
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" width="600px" append-to-body>
      <img width="100%" :src="showImg" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  props: {
    // 图片上传组件配置项
    options: {
      type: Object,
      default: () => {
        return {
          uploadUrl: '',
          limit: 1
        }
      }
    },
    fileList: {
      type: Array,
      default: () => []
    },
    uploadData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showImg: '',
      fileLists: this.fileList,
      dialogVisible: false
    }
  },
  watch: {
    fileList: function(val) {
      this.fileLists = val
    }
  },
  methods: {
    // 上传之前钩子
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isJPG) {
        this.$message.error('上传图片只支持JPG、JPEG、PNG格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 1MB!')
      }
      return isJPG && isLt1M
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.showImg = file.url
      this.dialogVisible = true
    },
    // 上传成功钩子
    handleSuccess(res, file) {
      this.fileLists.push({
        url: URL.createObjectURL(file.raw),
        raw: res.fileName,
        name: file.name
      })
      this.showImg = URL.createObjectURL(file.raw)
      this.$emit('imageUrl', this.setImageUrl(this.fileLists))
    },
    // 移除钩子
    handleRemove(file, fileList) {
      // console.log(file, this.fileLists, '上传清空')
      this.fileLists = this.fileLists.filter((item) => item.raw !== file.raw)
      this.$emit('imageUrl', this.setImageUrl(this.fileLists))
    },
    /**
     * @param {Array} arr 整理图片路径
     * */
    setImageUrl(arr) {
      const urls = []
      arr.forEach((item) => {
        urls.push({
          name: item.name,
          url: item.raw
        })
      })
      if (!arr.length) {
        return [
          {
            name: '',
            url: ''
          }
        ]
      }
      return urls
    },
    // 清空数据
    clearable() {
      this.fileLists = []
    }
  }
}
</script>

<style lang="stylus" scoped>
.noneBtnImg::v-deep
  .el-upload--picture-card
    display: none
.image-upload::v-deep
  .el-upload-list__item
    >div
      width: 100%
      height: 100%
</style>
